import React from 'react';

import cn from 'classnames';

import CounterPicture from '../../../../assets/images/pages/index/first_section/intro/compteur.png';

import classes from './counter.module.css';

const Counter = ({ className, pictureClassName }) => (
	<div className={cn(classes.container, className)}>
		<img
			className={cn(classes.counterPicture, pictureClassName)}
			src={CounterPicture}
			alt="Visuel Compteur"
		/>
	</div>
)

export default Counter;
