import React from 'react'

import cn from 'classnames'

import classes from './precision_performance_item.module.css'

const PrecisionPerformanceItem = ({
	className, icon, title, description
}) => (
	<div className={cn(classes.container, className)}>
		<div className={classes.iconContainer}>
			<img className={classes.icon} src={icon} alt={title} />
		</div>
		<div className={classes.title}>{title}</div>
		<div className={classes.description}>{description}</div>
	</div>
)

export default PrecisionPerformanceItem
