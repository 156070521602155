import React from 'react';
import { Link } from 'gatsby';

import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Section from '../../design_system/section/section';
import Counter from './counter/counter';
import PlayVideo from '../../small_views/play_video/play_video';
import OptimisedImage from '../../small_views/optimised_image/optimised_image';
import DownloadsLinks from '../../small_views/downloads_links/downloads_links';

import IphoneImage from '../../../assets/images/pages/index/first_section/intro/iphone/iphone.png';
import IphoneImageLowDef from '../../../assets/images/pages/index/first_section/intro/iphone/iphone_low_def.png';

import classes from './intro_section.module.css';

const IntroSection = () => {
	const useSmallLayout = useMediaQuery('(max-width: 850px)', { defaultMatches: true });
	const useSmallestLayout = useMediaQuery('(max-width: 560px)', { defaultMatches: true });
	return (
		<Section className={cn(classes.container, useSmallLayout && classes.smallLayoutContainer)}>
			<LayoutChangingContent {...{ useSmallLayout, useSmallestLayout }} />
		</Section>
	);
};

const LayoutChangingContent = ({ useSmallLayout, useSmallestLayout }) => {
	if (useSmallestLayout) {
		return (
			<>
				<Text />
				<CounterContainer {...{ useSmallestLayout }} />
				<div className={classes.phoneBottomContainer}>
					<Phone />
					<BottomContainer />
				</div>
			</>
		);
	}
	if (useSmallLayout) {
		return (
			<>
				<Text />
				<BottomContainer />
				<div className={classes.phoneCounterContainer}>
					<CounterContainer />
					<Phone />
				</div>
			</>
		)
	}
	return (
		<>
			<Phone />
			<div className={classes.side}>
				<div className={classes.textDownloadLinksPlayVideo}>
					<Text />
					<BottomContainer />
				</div>
				<CounterContainer />
			</div>
		</>
	);
}

const Phone = () => (
	<div className={classes.phonePictureContainer}>
		<OptimisedImage
			className={classes.phonePicture}
			normal={IphoneImage}
			lowDef={IphoneImageLowDef}
			alt="Iphone application"
		/>
	</div>
);

const Text = () => (
	<div className={classes.text}>
		{'Karnott associe un compteur connecté autonome à une application simple et innovante.'}
	</div>
);

const BottomContainer = () => (
	<div className={classes.bottomContainer}>
		<DownloadsLinks
			className={classes.downloadsLinks}
			linkClassName={classes.downloadPictureLink}
		/>
		<div className={classes.divider} />
		<PlayVideo
			className={classes.playVideo}
			textClassName={classes.playVideoText}
			pulseColor="light"
			changeTextColorOnHover="primary"
		/>
	</div>
);

const CounterContainer = ({ useSmallestLayout }) => {
	const isAbsoluteRelativeToContainer = useMediaQuery('(max-width: 710px)', { defaultMatches: true })
	return (
		<>
			<div className={classes.counterContainer}>
				<Counter
					className={classes.counter}
					pictureClassName={classes.counterPicture}
				/>
				{(!isAbsoluteRelativeToContainer || useSmallestLayout) && (
					<Link to="/solutions/lecompteur" className={classes.linkToCounter}>
						{'En savoir plus sur le compteur'}
					</Link>
				)}
			</div>
			{(isAbsoluteRelativeToContainer && !useSmallestLayout) && (
				<Link to="/solutions/lecompteur" className={classes.linkToCounter}>
					{'En savoir plus sur le compteur'}
				</Link>
			)}
		</>
	);
};

export default IntroSection;
