import React, { useMemo, useState, Fragment } from 'react';

import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import data from './precision_performance_section_data';

import Button from '../../design_system/button/button';
import Section from '../../design_system/section/section';
import SectionTitle from '../../design_system/section/section_title/section_title';
import PrecisionPerformanceItem from './precision_performance_item/precision_performance_item';
import Carousel from '../../design_system/carousel/carousel';

import classes from './precision_performance_section.module.css'

const PrecisionPerformanceSection = ({ setVisibleOnScroll = true, ...other }) => (
	<Section
		withClassName={classes.container}
		{...{ setVisibleOnScroll }}
		{...other}
	>
		{({ showSection }) => (
			<>
				<SectionTitle
					containerClassName={classes.sectionTitle}
					dismissUnderline={showSection}
				>
					{'Précision & Performance'}
				</SectionTitle>
				<Items />
				<div className={classes.buttonsContainer}>
					<Button
						className={classes.button}
						color="emerald"
						variant="outlined"
					>
						{'Créer un compte démo'}
					</Button>
					<Button
						className={classes.button}
						color="primary"
						variant="contained"
					>
						{'Demander un devis gratuit >'}
					</Button>
				</div>
			</>
		)}
	</Section>
);

const Divider = () => (
	<div className={classes.divider} />
);

const Items = () => {
	const showAsCarousel = useMediaQuery('(max-width: 1000px)', { defaultMatches: true });
	let content = null;
	if (showAsCarousel) {
		content = <ItemsInCarousel />
	} else {
		content = data.map((items, index) => (
			<Fragment key={`items_column_${index}`}>
				<ItemsColumn
					{...{ items }}
				/>
				{index < data.length - 1 && <Divider />}
			</Fragment>
		))
	}
	return (
		<div className={cn(classes.content, showAsCarousel && classes.contentAsCaroussel)}>
			{content}
		</div>
	);
}

const ItemsInCarousel = () => {
	const flattenItems = useMemo(() => data.reduce((flatObject, column) => {
		const flatObjectCopy = flatObject;
		Object.entries(column).forEach(([id, value]) => {
			flatObjectCopy[id] = value;
		});
		return flatObjectCopy;
	}, {}), [data]);
	const views = Object.entries(flattenItems).map(([id, item]) => (
		<PrecisionPerformanceItem
			key={`item_${id}`}
			className={classes.itemInCaroussel}
			{...item}
		/>
	))
	return (
		<Carousel
			arrowClassName={classes.carouselArrow}
			dots={false}
			responsive={[
				{
					breakpoint: 780,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 550,
					settings: {
						slidesToShow: 1,
						centerMode: true,
						centerPadding: 0
					}
				}]}
		>
			{views}
		</Carousel>
	)
};

const ItemsColumn = ({ items }) => (
	<div className={classes.itemsColumn}>
		{Object.entries(items).map(([id, item]) => (
			<PrecisionPerformanceItem
				key={`item_${id}`}
				{...item}
			/>
		))}
	</div>
);

export default PrecisionPerformanceSection;
