import ParkEquimentIcon from '../../../assets/images/pages/index/precision_performance_section/k-icn-parcmateriels.png';
import ActivityTrackingIcon from '../../../assets/images/pages/index/precision_performance_section/k-icn-suivitravaux.png';
import SurfacesControlIcon from '../../../assets/images/pages/index/precision_performance_section/k-icn-controlesurfaces.png';
import AccessibleDataIcon from '../../../assets/images/pages/index/precision_performance_section/k-icn-donneesaccessibles.png';
import AutomaticRepartitionIcon from '../../../assets/images/pages/index/precision_performance_section/k-icn-repartitionauto.png';
import RouteToThePlotIcon from '../../../assets/images/pages/index/precision_performance_section/k-icn-itineraireparcelles.png';


export default [
	{
		parkEquiment: {
			icon: ParkEquimentIcon,
			title: "Parc matériel en un coup d'œil",
			description: "Visualisation en un coup d'œil de tous vos matériels, actifs et inactifs"
		},
		activityTracking: {
			icon: ActivityTrackingIcon,
			title: 'Suivi des activités',
			description: 'Quels que soient les travaux : labour, semis, récolte, déchaumage, épandage, fauchage, …'
		}
	},
	{
		surfacesControl: {
			icon: SurfacesControlIcon,
			title: 'Contrôle des surfaces',
			description: 'Contrôle des surfaces et du nombre d’hectares réellement travaillés'
		},
		accessibleData: {
			icon: AccessibleDataIcon,
			title: 'Des données accessibles partout',
			description: 'Visualisation des données sur ordinateur, smartphone et tablette'
		}
	},
	{
		automaticRepartition: {
			icon: AutomaticRepartitionIcon,
			title: 'Répartition automatique',
			description: 'Par surfaces, hectares, distances, temps en parcelle ou hors parcelle, personnes ou encore entités, …'
		},
		routeToThePlot: {
			icon: RouteToThePlotIcon,
			title: 'Itinéraire à la parcelle',
			description: 'Trajet précis pour rejoindre une parcelle ou un chantier en cours'
		}
	}
];
