import React, {
	useEffect, useMemo, useRef, useState
} from 'react';

import cn from 'classnames';

import { config, interpolate, useTransition } from 'react-spring';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Section from '../../design_system/section/section';
import Carousel from '../../design_system/carousel/carousel';
import CustomersTypesItem from './customers_types_item/customers_types_item';

import useScrollWithReferenceCallback from '../../../hooks/use_scroll_with_reference_callback';
import customersTypes from './customers_types_data';

import classes from './customers_types_section.module.css';

const CustomersTypesSection = ({ ...other }) => {
	const containerReference = useRef();
	const showSection = useScrollWithReferenceCallback(containerReference, undefined, {
		transform: y => y + 100,
		substractWindowHeight: true
	});
	const showAsCarousel = useMediaQuery('(max-width: 1105px)', { defaultMatches: true });
	if (!customersTypes) {
		return null;
	}
	return (
		<Section
			ref={containerReference}
			withClassName={classes.container}
			{...other}
		>
			<div className={cn(classes.customersTypesContainer, showAsCarousel && classes.customersTypesContainerAsCarousel)}>
				<CustomersTypes {...{ showAsCarousel, showSection }} />
			</div>
		</Section>
	);
}

const CustomersTypes = ({ showAsCarousel, showSection }) => {
	if (showAsCarousel) {
		return (
			<CustomersTypesCarousel />
		);
	}
	return <CustomersTypesTransitions {...{ showSection }} />
}

const CustomersTypesCarousel = () => (
	<Carousel
		dots={false}
		arrowClassName={classes.carouselArrow}
		slidesToShow={4}
		responsive={[
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]}
	>
		{Object.entries(customersTypes)
			.map(([id, item]) => (
				<div key={`curstomer_type_${id}`}>
					<CustomersTypesItem
						{...item}
					/>
				</div>
			))}
	</Carousel>
)

const CustomersTypesTransitions = ({ showSection }) => {
	const [items, setItems] = useState();
	useEffect(() => {
		if (showSection && !items) {
			setItems(Object.entries(customersTypes))
		} else if (!showSection && items) {
			setItems(null);
		}
	}, [showSection, items])
	const transitions = useTransition(items, entry => entry && entry[0], {
		unique: true,
		trail: 120,
		config: config.slow,
		from: {
			scale: 0.9,
			opacity: 0
		},
		enter: {
			scale: 1,
			opacity: 1
		},
		leave: {
			scale: 0.9,
			opacity: 0
		}
	});
	return transitions.map(({ item, props: { scale, ...springProps }, key }, index) => item && (
		<CustomersTypesItem
			{...{ key }}
			{...item && item[1]}
			style={{
				top: index * 50,
				transform: interpolate([scale], scaleValue => `scale3d(${scaleValue}, ${scaleValue}, ${scaleValue})`),
				...springProps
			}}
		/>
	))
};

export default CustomersTypesSection;
