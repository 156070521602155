import React from 'react';
import { Link } from 'gatsby';

import Tag from '../../../design_system/tag/tag';
import Button from '../../../design_system/button/button';

import classes from './actuality_section_item.module.css';

const ActualitySectionItem = ({
	title,
	preview,
	content,
	tags,
	buttonColor = 'primary',
	link
}) => (
	<div className={classes.container}>
		<div className={classes.tagsContainer}>
			{tags.map(({ label, color }) => (
				<Tag
					className={classes.tag}
					{...{
						label,
						color
					}}
				/>
			))}
		</div>
		<Link to={link}>
			<div className={classes.titleContainer}>
				<div className={classes.title}>
					{title}
				</div>
			</div>
		</Link>
		<Link to={link}>
			<div className={classes.content}>
				{content}
			</div>
		</Link>
		<Link to={link}>
			<div className={classes.previewContainer}>
				<img
					className={classes.previewPicture}
					src={preview}
					alt={title}
				/>
			</div>
		</Link>
		<div className={classes.actionsContainer}>
			<Link to={link}>
				<Button
					className={classes.button}
					variant="underlined"
					bounce={false}
					color={buttonColor}
				>
					{'En savoir plus >'}
				</Button>
			</Link>
		</div>
	</div>
);

export default ActualitySectionItem;
