import React from 'react';

import { Link } from 'gatsby';

import exclusivesServicesData from './actuality_section_data';

import Button from '../../design_system/button/button';
import Section from '../../design_system/section/section';
import SectionTitle from '../../design_system/section/section_title/section_title';
import ActualitySectionItem from './actuality_section_item/actuality_section_item';

import classes from './actuality_section.module.css';

const ActualitySection = ({ setVisibleOnScroll = true, ...other }) => (
	<Section
		withClassName={classes.container}
		{...{ setVisibleOnScroll }}
		{...other}
	>
		{({ showSection }) => (
			<>
				<div className={classes.titleContainer}>
					<SectionTitle
						containerClassName={classes.sectionTitleContainer}
						dismissUnderline={showSection}
					>
						{'Actualités'}
					</SectionTitle>
				</div>
				<div className={classes.articlesContainer}>
					{Object.entries(exclusivesServicesData).map(([id, item]) => (
						<ActualitySectionItem
							key={`actuality_section_${id}`}
							{...item}
						/>
					))}
				</div>
				<div className={classes.buttonsContainer}>
					<Link to="/actus">
						<Button
							className={classes.button}
							color="emerald"
							variant="outlined"
						>
							{"Voir toute l'actu"}
						</Button>
					</Link>
					<Link to="/blog">
						<Button
							className={classes.button}
							color="emerald"
							variant="contained"
						>
							{'Découvrir notre blog >'}
						</Button>
					</Link>
				</div>
			</>
		)}
	</Section>
);

export default ActualitySection;
