import React from 'react'

import cn from 'classnames'

import classes from './exclusive_service.module.css'

const ExclusiveService = ({ show, icon, name, className }) => (
	<div className={cn(classes.container, show && classes.containerVisible, className)}>
		<img src={icon} alt={name} />
		<span className={classes.name}>{name}</span>
	</div>
)

export default ExclusiveService
