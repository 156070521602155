import React, { useMemo } from 'react'

import cn from 'classnames';

import Section from '../../design_system/section/section'
import SectionTitle from '../../design_system/section/section_title/section_title'
import Button from '../../design_system/button/button'
import ExclusiveService from './exclusive_service/exclusive_service'

import exclusivesServicesData from './exclusives_services_data'

import classes from './exclusives_services_section.module.css'

const ExclusivesServices = ({ setVisibleOnScroll = true, ...other }) => {
	return (
		<Section
			withClassName={classes.container}
			{...{ setVisibleOnScroll }}
			{...other}
		>
			{({ showSection }) => (
				<>
					<SectionTitle
						className={classes.title}
						underlineClassName={classes.underline}
						dismissUnderline={showSection}
					>
						{'Services exclusifs'}
					</SectionTitle>
					<div className={classes.exclusivesServicesContainer}>
						<Items />
					</div>
					<div className={classes.buttonsContainer}>
						<Button
							className={classes.button}
							color="primary"
							variant="outlined"
						>
							{'Créer un compte démo'}
						</Button>
						<Button
							className={cn(classes.button, classes.withTransparentLabelButton)}
							variant="contained"
						>
							{'Demander un devis gratuit >'}
						</Button>
					</div>
				</>
			)}
		</Section>
	);
}

const Items = () => {
	const entries = useMemo(() => Object.entries(exclusivesServicesData));
	return entries.map(([id, item]) => (
		<ExclusiveService
			key={`exclusive_service_${id}`}
			{...item}
		/>
	));
}

export default ExclusivesServices
