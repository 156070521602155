import React from 'react';

import PlayVideo from '../../small_views/play_video/play_video';
import Header from '../../small_views/header/header';

import classes from './index_header.module.css';

const IndexHeader = () => (
	<Header
		showSimaSticker
		showImportantActuality
		className={classes.header}
		titleClassName={classes.title}
	>
		<PlayVideo
			className={classes.playVideo}
			pulseColor="light"
			changeTextColorOnHover="primary"
		/>
	</Header>
);

export default IndexHeader;
