import React from 'react'

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';
import WithGradientLayout from '../components/layouts/with_gradient_layout/with_gradient_layout';

import IndexHeader from '../components/index/index_header/index_header';
import CustomersTypesSection from '../components/index/customers_types_section/customers_types_section';
import IntroSection from '../components/index/intro_section/intro_section';
import PrecisionPerformanceSection from '../components/index/precision_performance_section/precision_performance_section';
import IndexTestimonialsSection from '../components/index/index_testimonials_section/index_testimonials_section';
import ExclusivesServicesSection from '../components/index/exclusives_services_section/exclusives_services_section';
import ActualitySection from '../components/index/actuality_section/actuality_section';

const Application = () => (
	<HelmetLayout>
		<MainLayout
			showStickyItems={false}
			stickyItemsProps={{
				showImportantActualityPopup: true,
				showSimaSticker: true
			}}
		>
			<IndexHeader />
			<WithGradientLayout>
				<CustomersTypesSection />
				<IntroSection />
			</WithGradientLayout>
			<PrecisionPerformanceSection />
			<IndexTestimonialsSection />
			<WithGradientLayout>
				<ExclusivesServicesSection />
			</WithGradientLayout>
			<ActualitySection />
		</MainLayout>
	</HelmetLayout>
);

export default Application
