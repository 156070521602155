export default ({
	first: {
		title: 'Salon InnovAgri, nous y serons et vous ?',
		content: 'Venez nous retrouver sur le stand B53 les 5 et 6 septembre. Lore m ipsum dolor sit amet, consectetur adipiscing elit. Fusce scelerisq',
		preview: 'https://www.frenchweb.fr/wp-content/uploads/2017/05/Karnott.jpg',
		tags: [
			{ label: 'Event', color: '#f0921f' },
			{ label: 'Septembre', color: '#8b6706' },
			{ label: '2019', color: '#8cc047' }
		],
		buttonColor: 'primary',
		link: '/actu/actuality0'
	},
	second: {
		title: 'Nouvelle fonctionnalité',
		content: 'Ajouter des observations sur vos parcelles et matériels directement depuis l’application mobile Karnott.',
		preview: 'https://www.entreprendre.fr/wp-content/uploads/unnamed.jpg',
		tags: [
			{ label: 'App', color: '#35d5c9' },
			{ label: 'Septembre', color: '#8b6706' },
			{ label: '2019', color: '#8cc047' }
		],
		buttonColor: 'secondary',
		link: '/actu/actuality1'
	}
});
