import AgriIcon from '../../../assets/images/pages/index/first_section/customers_types/agri.svg';
import EtaIcon from '../../../assets/images/pages/index/first_section/customers_types/eta.svg';
import VitiIcon from '../../../assets/images/pages/index/first_section/customers_types/viti.svg';
import CumaIcon from '../../../assets/images/pages/index/first_section/customers_types/cuma.svg';
import IndustrielIcon from '../../../assets/images/pages/index/first_section/customers_types/industriel.svg';

export default Object.freeze({
	agri: {
		name: 'AGRI',
		icon: AgriIcon,
		link: '/solutions/agri'
	},
	eta: {
		name: 'ETA',
		icon: EtaIcon,
		link: '/solutions/eta'
	},
	viti: {
		name: 'VITI',
		icon: VitiIcon,
		link: '/solutions/viti'
	},
	cuma: {
		name: 'CUMA',
		icon: CumaIcon,
		link: '/solutions/cuma'
	},
	indu: {
		name: 'Industriel',
		icon: IndustrielIcon,
		link: '/solutions/industriels'
	}
});
