import React, { useCallback } from 'react';
import { Link } from 'gatsby';

import { animated, useSpring } from 'react-spring';

import classes from './customers_types_item.module.css';

const DEFAULT_ARROW_SPRING_PROPS = ({
	translation: 0
});

const CustomersTypesItem = ({
	icon: Icon, name, link, ...other
}) => {
	const [arrowSpringProps, setArrowSpringProps] = useSpring(() => DEFAULT_ARROW_SPRING_PROPS);
	const handleMouseEnter = useCallback(() => setArrowSpringProps(() => ({
		translation: 5
	})));
	const handleMouseLeave = useCallback(() => setArrowSpringProps(() => DEFAULT_ARROW_SPRING_PROPS));
	return (
		<animated.button
			className={classes.container}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			onBlur={handleMouseLeave}
			{...other}
		>
			<Link
				className={classes.link}
				to={link}
			>
				<div className={classes.icon}>
					<Icon />
				</div>
				<span className={classes.name}>
					{`${name}`}
					<animated.div
						className={classes.arrow}
						style={{
							transform: arrowSpringProps.translation.interpolate(value => `translate3d(${value}px, 0, 0)`)
						}}
					>
						{'>'}
					</animated.div>
				</span>
			</Link>
		</animated.button>
	);
};

export default CustomersTypesItem;
