import React from 'react';

import TestimonialsSection from '../../small_views/testimonials_section/testimonials_section';


const IndexTestimonialsSection = () => (
	<TestimonialsSection />
)

export default IndexTestimonialsSection;
