import QuickShippingIcon from '../../../assets/images/pages/index/exclusives_services/k-icn-livraison.png';
import SatisfiedOrefundedIcon from '../../../assets/images/pages/index/exclusives_services/k-icn-satisfaction.png';
import VideoTutorialsIcon from '../../../assets/images/pages/index/exclusives_services/k-icn-tutosvideo.png';
import PassionateAndReactiveTeamIcon from '../../../assets/images/pages/index/exclusives_services/k-icn-equipereactive.png';
import PersonalizedTrainingIcon from '../../../assets/images/pages/index/exclusives_services/k-icn-formation.png';
import WarrantyIcon from '../../../assets/images/pages/index/exclusives_services/k-icn-garantie2ans.png';

export default {
	quickShipping: {
		name: 'Livraison rapide',
		icon: QuickShippingIcon
	},
	satisfiedOrefunded: {
		name: 'Satisfait ou remboursé',
		icon: SatisfiedOrefundedIcon
	},
	videoTutorials: {
		name: 'Tutos vidéo',
		icon: VideoTutorialsIcon
	},
	personalizedTraining: {
		name: 'Formation personnalisé',
		icon: PersonalizedTrainingIcon
	},
	passionateAndReactiveTeam: {
		name: 'Équipe réactive et passionnée',
		icon: PassionateAndReactiveTeamIcon
	},
	warranty: {
		name: 'Garantie 2 ans',
		icon: WarrantyIcon
	}
};
